html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.App {
  text-align: center;
  background-color: #f4f5f7;
  padding: 20px;
  min-height: 100vh;  /* Ensures it covers the full viewport height */
  box-sizing: border-box; /* Includes padding in the height calculation */
}
