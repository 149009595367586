.banner {
    background: linear-gradient(50deg, #f9f9f9, #dcdcdc);
    padding: 2px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(28, 29, 31, 0.05);
    transition: transform 0.2s ease-in-out; /* Adds smooth transition for the hover effect */
  }
  
  .banner:hover {
    transform: translateY(-2px); /* Moves the banner up by 2 pixels */
  }
  
  
  .announcement {
    font-size: 16px;  /* Adjust font size as needed */
    color: #1c1d1f;        /* Adjust color as needed */
    margin: 10px 0;     /* Provides some spacing around the text */
    font-weight: 500;
  }
  

  /* Mobile responsiveness */
@media (max-width: 768px) {

  .announcement {
    font-size: 13px; /* Adjust the font size to ensure readability on small devices */
  }
}

/* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .banner {
    padding: 10px 18px; /* Slightly larger padding than mobile */
  }

  .announcement {
    font-size: 14px; /* Slightly larger font size than mobile for better readability */
  }
}
