.invest-gallery {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.invest-card {
  width: 300px; /* Standard width for non-featured cards */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s, width 0.2s;
  background-color: #fff;
  cursor: pointer;
}

.featured-card {
  background-color: #1c1d1f; /* Darker background for featured cards */
  color: white; /* Change text color for contrast */
  width: 300px; /* Standard width for non-featured cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height:311px;
  margin: 10px;

}

.invest-card:hover {
  transform: scale(1.05);
}

.invest-card img {
  width: 100%;
  height: auto;
}

.invest-info {
  color: #333; /* Default text color for non-featured cards */
}

.featured-card .invest-info {
  color: #fff; /* White text for featured cards */
}

.invest-info h2 {
  margin-top: 10px;
}

.invest-info .invest-price {
  color: #18c290; /* Highlight color for link text */
}

.invest-button {
  background-color: #18c290;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.invest-button:hover {
  background-color: #17b485;
}

/* Mobile responsiveness (max width: 768px) */
@media (max-width: 768px) {
  .invest-gallery {
    flex-direction: column;
    align-items: center;
  }

  .invest-card {
    width: 90%; /* Use more of the screen for each card */
    margin: 10px 0; /* Reduce margin to save space */
  }

  .featured-card {
    width: 90%; /* Consistency in width for featured cards */
    height:auto;

  }

  .invest-button {
    padding: 8px 16px; /* Slightly smaller padding */
  }
}

/* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .invest-gallery {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; /* Ensures even spacing */
  }

  .invest-card {
    width: 45%; /* Accommodate two cards in a row */
    margin: 15px; /* Slightly more margin for better spacing */
  }

  .featured-card {
    width: 70%; /* Consistency in width for featured cards */
    height:auto;
  }


  .invest-button {
    padding: 10px 20px; /* Comfortable touch area */
  }
}
