.footer {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    margin-top: 50px;
}

.footer-top {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(204, 204, 204, 0.7); /* CCC with 0.7 opacity */
    padding: 0px;
}

.footer-logo {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.footer-logo img {
    width: 32px;
    margin-right: 2px;
}

.footer-logo span {
    font-weight: bold;
    color: #333;
    font-size: 12px;
}

.footer-columns {
    display: flex;
    justify-content: space-around;
    align-items: start; /* Aligns items to the top */
    width: 100%;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the left */
}

.footer-section h4 {
    margin-bottom: 10px;
    color: #333;
    text-align: left; /* Ensures the header is left-aligned */
}

.footer-section ul {
    list-style: none;
    padding: 0;
    width: 100%; /* Ensures the ul occupies the full width of the section */
}

.footer-section ul li {
    padding: 3px 0;
    color: #666;
    text-align: left; /* Ensures the list items are left-aligned */
}
/* Links in the footer */
.footer-section a {
    text-decoration: none;  /* Removes underline */
    color: #666;            /* Base link color */
    transition: color 0.3s ease;  /* Smooth transition for hover effect */
}

.footer-section a:hover {
    color: #333;  /* Darker color on hover */
    text-decoration: underline;  /* Optional underline on hover */
}


/* FOOTER BOTTOM */
/* FOOTER BOTTOM */
/* FOOTER BOTTOM */
/* FOOTER BOTTOM */

.footer-bottom {
    margin-top: 20px;
    font-size: 12px;
    text-align: left;
}

.footer-border {
    height: 1px;
    background-color: #ccc; /* Using a background color for the border effect */
    width: 100%; /* Ensures it spans the full width of the footer */
    opacity: 0.7;
}

.footer-bottom p, .footer-bottom a, .footer-bottom span {
    color: #666;
    text-decoration: none;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; /* Adds spacing between border and links */
}

.footer-legal {
    display: flex;
    flex-wrap: wrap; /* Allows content to wrap */
    align-items: center;
    width: 100%;
}

.footer-legal a, .footer-legal span {
    margin-right: 10px;
}

.footer-legal .footer-country {
    margin-left: auto; /* This pushes the 'United States' to the right */
}


/* Mobile responsiveness (max width: 768px) */
@media (max-width: 768px) {
    .footer-top {
        justify-content: center; /* Centers the logo and accompanying text */
    }

    .footer-logo {
        align-items: center; /* Centers the content horizontally */
        text-align: center; /* Centers the text */
    }

    .footer-columns {
        flex-direction: column;
        align-items: center; /* Centers the footer sections */
    }

    .footer-section h4 {
        text-align: center; /* Centers section titles */
    }

    .footer-bottom {
        text-align: center; /* Centers text in the footer bottom section */
        font-size: 10px; /* Slightly smaller font for mobile */
    }

    .footer-links {
        flex-direction: column;
        text-align: center;
        margin-top: 10px;
    }

    .footer-legal {
        justify-content: center;
        text-align: center;
    }
}
  
  /* Tablet responsiveness (769px to 1024px) */
  @media (min-width: 769px) and (max-width: 1024px) {
    .footer-columns {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; /* Spread out sections */
    }
  
    .footer-section {
      width: 45%; /* Makes each section take up 45% of the width for better layout */
      margin-bottom: 20px;
    }
  
    .footer-logo {
      justify-content: center;
    }
  
    .footer-bottom {
      font-size: 12px; /* Regular font size for tablet */
      text-align: left;
    }
  
    .footer-legal {
      justify-content: space-between;
      flex-wrap: nowrap; /* Avoid wrapping on tablets */
    }
  
    .footer-country {
      margin-left: auto; /* Pushes the country name to the right */
    }
  }
  