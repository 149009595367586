.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allows scrolling */
}

.modal-content {
  position: relative;
  width: 65%; /* Set the modal width to 65% of the container */
  padding-top: 36.5625%; /* Adjusted to maintain a 16:9 aspect ratio based on the new width */
  border-radius: 10px;
  overflow: hidden;
  margin: auto; /* Centers the modal in the overlay */
}

.modal-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    width: 80%; /* Takes more width on smaller screens */
    padding-top: 45%; /* Adjusted to maintain a 16:9 aspect ratio based on the new width */
  }
}

/* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .modal-content {
    width: 70%; /* Slight increase for tablets */
    padding-top: 39.375%; /* Adjusted to maintain a 16:9 aspect ratio */
  }
}
