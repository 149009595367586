
/* Top Fold container styling */
.topfold-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
}




/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: rgba(22, 22, 22, 0.9);
  border-radius: 20px;

}

/* Logo */
.logo-container .logo {
  height: 45px;
}

/* Buttons */
.nav-buttons {
  display: flex;
  gap: 10px;
}

/* Content Catalog Button */
.content-catalog-btn {
  background: none;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.3s ease;
  opacity: 0.8;
}

.content-catalog-btn:hover {
  transform: scale(1.02);
  opacity: 1;

}

/* Download Website Button */
.download-site-btn {
  background: linear-gradient(165deg, #f9f9f9, #dcdcdc);
  color: #1c1d1f;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: transform 0.3s ease;
}

.download-site-btn:hover {
  transform: scale(1.02);
}



/* Hero Section */
.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 70px 20px;
  margin-top: 10px; /* Add this margin to create the gap */
  background: linear-gradient(135deg, #f9f9f9, #dcdcdc);
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(28, 29, 31, 0.1);
}

.hero-title {
  font-size: 4rem;
  margin-bottom: 20px;
  color: #1c1d1f;
}

.hero-subtitle {
  font-size: 2.1rem;
  margin-bottom: 40px;
  max-width: 700px;
  color: #6d6e73;
}

.cta-buttons {
  display: flex;
  gap: 20px;
}
/* General button style */
.watchdemo-btn {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #161616;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(28, 29, 31, 0.3);
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background color */
  text-decoration: none;

}

.watchdemo-btn:hover {
  transform: translateY(-5px); /* Moves the button up by 5 pixels */
}


.play-icon {
  width: 50px; /* Adjust size as needed */
  margin-right: 10px; /* Add some space between the icon and text */
}

.demo-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  text-decoration: none;
}

.demo-duration {
  font-size: 0.9rem;
  opacity: 0.8;
  margin-top: 5px; /* Add spacing between "watch demo" and "1 minute" */
}

/* Mobile responsiveness (max width: 768px) */
@media (max-width: 768px) {

  .navbar {
    padding: 10px 15px;
  }

  .content-catalog-btn,
  .download-site-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .nav-buttons {
    gap: 8px;
  }

  .hero-title {
    font-size: 2.4rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .watchdemo-btn {
    width: 100%;
  }

  .logo {
    height: 40px;
  }

  .hero-section {
    padding: 40px 15px;
  }
}

/* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .navbar {
    padding: 10px 15px;
  }

  .logo {
    height: 40px;
  }

  .nav-buttons {
    gap: 8px;
  }

  .content-catalog-btn,
  .download-site-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.8rem;
  }

  .cta-buttons {
    gap: 15px;
  }

  .hero-section {
    padding: 60px 20px;
  }

  .watchdemo-btn {
    padding: 8px 12px;
    font-size: 1rem;
  }

  .play-icon {
    width: 40px;
  }

  .demo-duration {
    font-size: 0.85rem;
  }
}
