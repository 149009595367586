.newsletter-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 70px 20px;
    margin: 20px auto; /* Centering horizontally and setting the top margin */
    width: 60%; /* Adjust this percentage to control the width as desired */
    max-width: 700px; /* Optional: Ensures it doesn't get too wide on larger screens */
    background: linear-gradient(135deg, #1c1d1f, #2e2f31);
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

  
  .newsletter-cta h1, .newsletter-cta p {
    margin: 0 0 20px 0;
  }
  
  
  .newsletter-cta input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.9);
    margin-right: 10px;
    outline: none; /* Removes the outline on focus */
}

  
.newsletter-cta button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #444;
    color: white;
    transition: all 0.3s ease;  /* Ensures transitions apply to both background color and transform */
    font-weight: bold;
  }
  
  .newsletter-cta button:hover {
    background-color: #515050;
    transform: scale(1.02); /* Slightly increases size on hover */
  }
  
  .newsletter-cta button:disabled {
    background-color: #ccc; /* Lighter color for disabled state */
    cursor: not-allowed;
  }
  
  
  
  .newsletter-cta button.button-success {
    background-color: #18c290; /* Green background */
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  /* Mobile responsiveness (max width: 768px) */
@media (max-width: 768px) {
    .newsletter-cta {
      width: 90%; /* Makes the container more narrow on smaller screens */
      padding: 50px 10px; /* Adjusts padding for smaller screens */
    }
  
    .newsletter-cta h1 {
      font-size: 1.8rem; /* Reduce font size for headings */
    }
  
    .newsletter-cta p {
      font-size: 1rem; /* Reduce font size for paragraph text */
    }
  
    .newsletter-cta input {
      width: 93%; /* Make the input take full width */
      margin-bottom: 15px; /* Add margin to the bottom for spacing */
    }
  
    .newsletter-cta button {
      width: 60%; /* Make the button take full width */
    }
  }
  
  /* Tablet responsiveness (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .newsletter-cta {
        width: 90%; /* Makes the container more narrow on smaller screens */
        padding: 50px 10px; /* Adjusts padding for smaller screens */
      }
    
      .newsletter-cta h1 {
        font-size: 1.8rem; /* Reduce font size for headings */
      }
    
      .newsletter-cta p {
        font-size: 1rem; /* Reduce font size for paragraph text */
      }
    
      .newsletter-cta input {
        width: 95%; /* Make the input take full width */
        margin-bottom: 15px; /* Add margin to the bottom for spacing */
      }
    
      .newsletter-cta button {
        width: 50%; /* Make the button take full width */
      }
  }
  